import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'

import createPersistedState from 'vuex-persistedstate'
import companyDocuments from '@/store/modules/company_documents'
import companySettings from '@/store/modules/company_settings'
import defaultDocuments from '@/store/modules/default_documents'
import userDocuments from '@/store/modules/user_documents'
import order from '@/store/modules/order'
import subscription from '@/store/modules/subscription'
import product from '@/store/modules/product'
import company from '@/store/modules/company'
import tickets from '@/store/modules/tickets'
import images from '@/store/modules/images'
import notification from './modules/notification'
import breadcrumb from './modules/breadcrumb'
import user from './modules/user'
import procedure from './modules/procedure'
import procedureTemplates from './modules/procedure_templates'
import auth from './modules/auth'
import partner from './modules/partner'
import group from '@/store/modules/group'
import groupDocuments from '@/store/modules/group_documents'
import app from './app'
import { getUserAbility } from '@/plugins/acl/config'

const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const initialState = {
  snackbar: {
    message: '',
    type: '',
  },
  load_data: true,
  loadDataInterval: 60000,
  formRules: {
    required: value => !!value || 'Obbligatorio',
    emailRules: v => /.+@.+/.test(v) || 'Il formato email non è corretto',
    cfRule: v => /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/.test(v) || 'Codice fiscale non valido',
    pswRule: v => {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

      return v.length > 8 && /\d/.test(v) && /[A-Z]/.test(v) && format.test(v)
    },
    pswMatch: (matchPsw, firstPsw) => {
      if (matchPsw === firstPsw) {
        return true
      }

      return 'Le password non coincidono.'
    },
  },
  productFunctionalities: {
    canCreateProcedure: {
      status: true,
      details: {
        number_limit: true,
        space_limit: true,
      },
    },
  },
  productPercentages: {
    proceduresNum: 0,
    proceduresSize: 0,
    users: 0,
  },
  progress: 0,
  progressIterations: 0,
  procedure: {},
}

export default new Vuex.Store({
  state: initialState,
  getters: {
    formRules: state => state.formRules,
    productPercentages: state => state.productPercentages,
    productLimits: state => ({
      proceduresNum: state.subscription.product_config.procedure_number_limit,
      proceduresSize: state.subscription.product_config.procedure_space_limit,
    }),
    productFunctionalities: state => state.productFunctionalities,
    progress: state => state.progress,
    loadDataInterval: state => state.loadDataInterval,
  },
  mutations: {
    SET_ALERT(state, data) {
      state.snackbar.message = data.msg
      state.snackbar.type = data.type
    },
    RESET_ALERT(state) {
      state.snackbar.message = ''
      state.snackbar.type = ''
    },
    SET_LOAD_DATA(state, data) {
      state.load_data = data
    },
    SET_CAN_CREATE_PROCEDURE(state, data) {
      state.productFunctionalities.canCreateProcedure.status = data
    },
    resetStore(state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key])
      })
    },
    SET_PROGRESS(state, progressVal) {
      state.progress = progressVal
    },
    SET_PROGRESS_ITERATIONS(state, progressIterationsVal) {
      state.progressIterations = progressIterationsVal
    },
    SET_PRODUCT_PERCENTAGES(state, payload) {
      state.productPercentages[payload.key] = payload.val
    },
    SET_PRODUCT_FUNCTIONALITIES(state, payload) {
      state.productFunctionalities.canCreateProcedure.details[payload.key] = payload.val
    },
    SET_PRODUCT_FUNCTIONALITY_STATUS(state, payload) {
      state.productFunctionalities[payload.key].status = payload.val
    },
  },
  actions: {
    async set_alert({ commit }, payload) {
      commit('SET_ALERT', payload)
      setTimeout(() => {
        commit('RESET_ALERT')
      }, 3000)
    },
    async set_load_data({ commit }, payload) {
      commit('SET_LOAD_DATA', payload)
    },
    async reload_subscription_data({ dispatch }) {
      await dispatch('getCurrentSubscription')
      const respLicense = await dispatch('getLicense')
      if (respLicense.data.status !== 'success') {
        await dispatch('set_load_data', false)
      }
    },
    async reload_all_data({ dispatch, commit }) {
      commit('SET_PROGRESS', 0)
      commit('SET_PROGRESS_ITERATIONS', 14)

      await dispatch('reload_general_data', false)
      await dispatch('reload_specific_data', false)
    },
    async reload_general_data({ dispatch, commit }, execThisOnly = true) {
      if (execThisOnly) {
        commit('SET_PROGRESS', 0)
        commit('SET_PROGRESS_ITERATIONS', 10)
      }

      await dispatch('getUserInfoAll')
      await dispatch('updateProgressVal')

      await dispatch('getUserAvatar', this.state.user.userInfo.id)
      await dispatch('updateProgressVal')

      await dispatch('getTickets')
      await dispatch('updateProgressVal')

      await dispatch('getTicketCategories')
      await dispatch('updateProgressVal')

      if (this.state.user.userInfo.role === 'professional') {
        await dispatch('getCompanyAvatar', this.state.user.companyInfo.id)
        await dispatch('updateProgressVal')

        await dispatch('getCurrentSubscription')
        await dispatch('updateProgressVal')

        const respLicense = await dispatch('getLicense')
        await dispatch('updateProgressVal')

        if (respLicense.data.status !== 'success') {
          await dispatch('set_load_data', false)
        }
      }
      await dispatch('getProducts')
      await dispatch('updateProgressVal')

      await dispatch('getDefaultDocuments')
      await dispatch('updateProgressVal')
    },
    async reload_specific_data({ dispatch, commit }, execThisOnly = true) {
      if (execThisOnly) {
        commit('SET_PROGRESS', 0)
        commit('SET_PROGRESS_ITERATIONS', 5)
      }

      await dispatch('getProcedures')
      await dispatch('updateProgressVal')

      await dispatch('getAvatars', true)
      await dispatch('updateProgressVal')

      if (this.state.user.userInfo.role === 'professional') {
        await dispatch('getProcedureTemplates')
        await dispatch('updateProgressVal')

        await dispatch('getCompanyDocuments')
        await dispatch('updateProgressVal')

        await dispatch('getCompanySettings')
        await dispatch('updateProgressVal')

        await dispatch('setProductPercentages')
        await dispatch('setProductFunctionalities')
      } else {
        await dispatch('getUserDocuments')
        await dispatch('updateProgressVal')
      }
    },
    updateProgressVal({ commit }, setVal = null) {
      if (setVal >= 100) {
        commit('SET_PROGRESS', 100)
      } else {
        const progress = this.state.progress + Math.random() * this.state.progressIterations
        if (progress >= 100) {
          commit('SET_PROGRESS', 100)
        } else {
          commit('SET_PROGRESS', progress)
        }
      }
    },
    setProductPercentages({ commit, state }) {
      // procedures
      if (state.procedure.procedureCounters.open >= state.subscription.product_config.procedure_number_limit) {
        commit('SET_PRODUCT_PERCENTAGES', { key: 'proceduresNum', val: 100 })
      } else {
        const value = (state.procedure.procedureCounters.open / state.subscription.product_config.procedure_number_limit) * 100
        commit('SET_PRODUCT_PERCENTAGES', { key: 'proceduresNum', val: value })
      }

      if (state.procedure.procedureCounters.total_space > (state.subscription.product_config.procedure_space_limit * 1000000000)) {
        commit('SET_PRODUCT_PERCENTAGES', { key: 'proceduresSize', val: 100 })
      } else {
        const value = (state.procedure.procedureCounters.total_space / (state.subscription.product_config.procedure_space_limit * 1000000000)) * 100
        commit('SET_PRODUCT_PERCENTAGES', { key: 'proceduresSize', val: value })
      }

      const numberLimit = state.procedure.procedureCounters.open < state.subscription.product_config.procedure_number_limit
      const spaceLimit = state.procedure.procedureCounters.total_space < state.subscription.product_config.procedure_space_limit * 1000000000
      console.log(spaceLimit)
      commit('SET_PRODUCT_FUNCTIONALITIES', { key: 'number_limit', val: numberLimit })
      commit('SET_PRODUCT_FUNCTIONALITIES', { key: 'space_limit', val: spaceLimit })
    },
    setProductFunctionalities({ commit, state }) {
      let temp = true
      Object.keys(state.productFunctionalities.canCreateProcedure.details).map(key => {
        if (state.productFunctionalities.canCreateProcedure.details[key] === false) {
          temp = false
        }
      })

      commit('SET_PRODUCT_FUNCTIONALITY_STATUS', { key: 'canCreateProcedure', val: temp })
    },
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    procedure,
    procedureTemplates,
    user,
    breadcrumb,
    notification,
    companyDocuments,
    companySettings,
    defaultDocuments,
    userDocuments,
    order,
    subscription,
    product,
    company,
    tickets,
    images,
    partner,
    group,
    groupDocuments,
  },
  plugins: [createPersistedState({
    key: 'lmpo-store',
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key),
    },
    reducer(val) {
      //console.log(val.auth)
      //  && (val.auth.apiKey === null || val.auth.apiKey === undefined || val.auth.apiKey === '')
      if (val.auth.token === null) {
        return {}
      }

      return val
    },
  })],
})
